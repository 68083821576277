<template lang="pug">
  div
    v-row
      v-col(lg="3" cols="12")
        create-feeding-program-category(
          :default-field-values.sync="feedingProgramCategoryData"
        )
      v-col(lg="9" cols="12")
        table-feeding-program-category(
          @edit="item => { feedingProgramCategoryData = item }"
        )
</template>
<script>
export default {
  name: 'FeedingProgramCategory',
  components: {
    createFeedingProgramCategory: () => import('./Create'),
    tableFeedingProgramCategory: () => import('./Table'),
  },
  data: () => ({
    feedingProgramCategoryData: {},
  }),
}
</script>